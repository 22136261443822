type Icon = {
  name: string;
  url: string;
};

export const ANGULAR_SVG_ICONS: Icon[] = [
  {
    name: 'alert-triangle',
    url: 'assets/svgs/alert-triangle.svg',
  },
  {
    name: 'check',
    url: 'assets/svgs/check.svg',
  },
  {
    name: 'chevron',
    url: 'assets/svgs/chevron.svg',
  },
  {
    name: 'plus',
    url: 'assets/svgs/plus.svg',
  },
];

export const MATERIAL_SVG_ICONS: Icon[] = [
  {
    name: 'onboardr-minimize',
    url: 'assets/icons/onboardr-minimize.svg',
  },
  {
    name: 'onboardr-rectangle',
    url: 'assets/icons/onboardr-rectangle.svg',
  },
  {
    name: 'onboardr-companies',
    url: 'assets/icons/onboardr-companies.svg',
  },
  {
    name: 'onboardr-admin',
    url: 'assets/icons/onboardr-admin.svg',
  },
  {
    name: 'onboardr-logout',
    url: 'assets/icons/onboardr-logout.svg',
  },
  {
    name: 'onboardr-info',
    url: 'assets/icons/onboardr-info.svg',
  },
];
