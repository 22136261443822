import { createReducer, on } from '@ngrx/store';
import { emptySchemeType, initialSchemeTypesState } from './scheme-types.state';
import {
  createSchemeTypeSuccess,
  deleteSchemeTypeSuccess,
  getAllSchemeTypes,
  getAllSchemeTypesSuccess,
  getSchemeType,
  getSchemeTypeSuccess,
  resetSchemeTypeDeleted,
  resetSchemeTypeUpdated,
  updateSchemeTypeSuccess,
} from './scheme-types.action';

export const schemeTypesReducer = createReducer(
  initialSchemeTypesState,
  on(getAllSchemeTypes, state => ({ ...state, loadingSchemeTypes: true })),
  on(getAllSchemeTypesSuccess, (state, { schemeTypes }) => ({
    ...state,
    schemeTypes,
    loadingSchemeTypes: false,
  })),
  on(getSchemeType, state => ({ ...state, loadingSchemeType: true })),
  on(getSchemeTypeSuccess, (state, { schemeType }) => ({
    ...state,
    schemeType,
    loadingSchemeType: false,
  })),
  on(createSchemeTypeSuccess, (state, { schemeType }) => ({
    ...state,
    schemeType,
    schemeTypeUpdated: true,
  })),
  on(updateSchemeTypeSuccess, (state, { schemeType }) => ({
    ...state,
    schemeType,
    schemeTypeUpdated: true,
  })),
  on(resetSchemeTypeUpdated, state => ({ ...state, schemeTypeUpdated: false })),
  on(deleteSchemeTypeSuccess, state => ({
    ...state,
    schemeType: emptySchemeType,
    schemeTypeDeleted: true,
  })),
  on(resetSchemeTypeDeleted, state => ({ ...state, schemeTypeDeleted: false }))
);
