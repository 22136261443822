import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserApiService } from "../../api/user-api.service";
import { UserActionType, createUser, createUserSuccess, deleteUser, deleteUserSuccess, getAllUsers, getAllUsersSuccess, getUser, getUserSuccess, updateUser, updateUserPassword, updateUserPasswordSuccess, updateUserSuccess } from "./user.action";
import { EMPTY, catchError, exhaustMap, map, of } from "rxjs";

@Injectable()
export class UserEffects {
    getAllUsers$ = createEffect(() =>
        this.actions$.pipe(
          ofType(getAllUsers),
          exhaustMap(() =>
            this.userAPI.getAllUsers().pipe(
              map(users => getAllUsersSuccess({users})),
              catchError(() => EMPTY)
            )
          )
        )
      );
    
      getUser$ = createEffect(() =>
        this.actions$.pipe(
          ofType(getUser),
          exhaustMap((action) =>
            this.userAPI.getUser(action.id).pipe(
              map(user => getUserSuccess({user})),
              catchError(() => EMPTY)
            )
          )
        )
      );
    
      createUser$ = createEffect(() =>
        this.actions$.pipe(
          ofType(createUser),
          exhaustMap(action =>
            this.userAPI.createUser(action.user).pipe(
              map(user => createUserSuccess({user})),
              catchError((e) => of({ type: UserActionType.CREATE_USER_ERROR, error: e }))
            )
          )
        )
      );
    
      updateUser$ = createEffect(() =>
        this.actions$.pipe(
          ofType(updateUser),
          exhaustMap((action) =>
            this.userAPI.updateUser(action.user).pipe(
              map(user => updateUserSuccess({user})),
              catchError(() => EMPTY)
            )
          )
        )
      );

      updateUserPassword$ = createEffect(() =>
        this.actions$.pipe(
          ofType(updateUserPassword),
          exhaustMap((action) =>
            this.userAPI.updateUserPassword(action.user).pipe(
              map(() => updateUserPasswordSuccess()),
              catchError(() => EMPTY)
            )
          )
        )
      );
    
      deleteUser$ = createEffect(() =>
        this.actions$.pipe(
          ofType(deleteUser),
          exhaustMap((action) =>
            this.userAPI.deleteUser(action.id).pipe(
              map(() => deleteUserSuccess()),
              catchError(() => EMPTY)
            )
          )
        )
      );
    
    constructor(
        private actions$: Actions,
        private userAPI: UserApiService
    ) {}
}