import { createAction, props } from "@ngrx/store";
import { ProgramOrigin } from "./program-origin.model";

export enum ProgramOriginActionType {
  GET_ALL_PROGRAM_ORIGINS = '[ProgramOrigin] Get All ProgramOrigins',
  GET_ALL_PROGRAM_ORIGINS_SUCCESS = '[ProgramOrigin] Get All ProgramOrigins :: Success'
}

export const getAllProgramOrigins = createAction(ProgramOriginActionType.GET_ALL_PROGRAM_ORIGINS);
export const getAllProgramOriginsSuccess = createAction(
  ProgramOriginActionType.GET_ALL_PROGRAM_ORIGINS_SUCCESS,
  props<{ programOrigins: ProgramOrigin[] }>()
);
