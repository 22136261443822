import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, catchError, exhaustMap, map } from "rxjs";
import { RouteApiService } from "../../api/route-api.service";
import { getAllRoutes, getAllRoutesSuccess } from "./route.action";

@Injectable()
export class RouteEffects {
  getAllRoutes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllRoutes),
      exhaustMap(() =>
        this.routeAPI.getAllRoutes().pipe(
          map(routes => getAllRoutesSuccess({routes})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private routeAPI: RouteApiService
  ) {}
}
