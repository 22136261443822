import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from '../../login/services/authentication.service';
import { catchError, throwError } from 'rxjs';
import { removeLocalStorage } from '../../login/utils/local-storage-utils';
import { AUTH_VARIABLES } from '../../login/utils/auth-variables';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { resetUserInfo } from '../store/company/company.action';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthenticationService);
  const store = inject(Store);
  const router = inject(Router);
  const authToken = authService.getAuthToken();
  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken}`
    }
  });
  return next(authReq).pipe(
    catchError((error: any) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          authService.signOut().subscribe(() => {
            removeLocalStorage(AUTH_VARIABLES.companyUserInfo);
            store.dispatch(resetUserInfo());
            router.navigate(['']);
          });
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      } else {
        return throwError(() => error);
      }
    })
  );
};
