import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SchemeType } from '../store/scheme-types/scheme-types.model';
import { environment } from '../../../environments/environment';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchemeTypesApiService {
  readonly http = inject(HttpClient);

  getAllSchemeTypes(): Observable<SchemeType[]> {
    return this.http.get<SchemeType[]>(environment.apiRoutes.schemeTypes).pipe(shareReplay(1));
  }

  getSchemeType(id: number) {
    return this.http
      .get<SchemeType>(`${environment.apiRoutes.schemeTypes}${id}`)
      .pipe(shareReplay(1));
  }

  createSchemeType(schemeType: SchemeType): Observable<SchemeType> {
    return this.http.post<SchemeType>(environment.apiRoutes.schemeTypes, schemeType);
  }

  updateSchemeType(schemeType: SchemeType): Observable<SchemeType> {
    return this.http.patch<SchemeType>(
      `${environment.apiRoutes.schemeTypes}${schemeType.id}`,
      schemeType
    );
  }

  deleteSchemeType(id: number): Observable<SchemeType> {
    return this.http.delete<SchemeType>(`${environment.apiRoutes.schemeTypes}${id}`);
  }
}
