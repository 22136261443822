import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { IconsService } from './core/services/icons.service';
import { OnboardrSpinnerComponent } from './shared/components/onboardr-spinner/onboardr-spinner.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, OnboardrSpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  iconsService = inject(IconsService);

  constructor() {
    this.iconsService.loadAngularSvgIcons();
    this.iconsService.loadMaterialCustomIcons();
  }
}
