import { createReducer, on } from '@ngrx/store';
import { emptyScheme, initialSchemeState } from './scheme.state';
import {
  createSchemeSuccess,
  deleteSchemeSuccess,
  getAllSchemes,
  getAllSchemesSuccess,
  getScheme,
  getSchemeSuccess,
  getSchemeTypesAction,
  getSchemeTypesSuccessAction,
  resetSchemeDeleted,
  resetSchemeUpdated,
  updateSchemeSuccess,
} from './scheme.action';

export const schemeReducer = createReducer(
  initialSchemeState,
  on(getAllSchemes, state => ({ ...state, loadingSchemes: true })),
  on(getAllSchemesSuccess, (state, { schemes }) => ({
    ...state,
    schemes: schemes,
    loadingSchemes: false,
  })),
  on(getScheme, state => ({ ...state, loadingScheme: true })),
  on(getSchemeSuccess, (state, { scheme }) => ({ ...state, scheme: scheme, loadingScheme: false })),
  on(createSchemeSuccess, (state, { scheme }) => ({
    ...state,
    scheme: scheme,
    schemeUpdated: true,
  })),
  on(updateSchemeSuccess, (state, { scheme }) => ({
    ...state,
    scheme: scheme,
    schemeUpdated: true,
  })),
  on(resetSchemeUpdated, state => ({ ...state, schemeUpdated: false })),
  on(deleteSchemeSuccess, state => ({ ...state, scheme: emptyScheme, schemeDeleted: true })),
  on(resetSchemeDeleted, state => ({ ...state, schemeDeleted: false }))
);
