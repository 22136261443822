import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';
import { ElementsApiService } from '../../api/elements-api.service';
import {
  createElement,
  createElementSuccess,
  deleteElement,
  deleteElementSuccess,
  getAllElements,
  getAllElementsSuccess,
  getElement,
  getElementSuccess,
  updateElements,
  updateElementsSuccess,
} from './element.action';

@Injectable()
export class ElementEffects {
  constructor(
    private actions$: Actions,
    private elementsAPI: ElementsApiService
  ) {}

  getAllElements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllElements),
      exhaustMap(() =>
        this.elementsAPI.getAllElements().pipe(
          map(elements => getAllElementsSuccess({ elements })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getElement),
      exhaustMap(({ id }) =>
        this.elementsAPI.getElement(Number(id)).pipe(
          map(element => getElementSuccess({ element })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createElement),
      exhaustMap(({ element }) =>
        this.elementsAPI.createElement(element).pipe(
          map(element => createElementSuccess({ element })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateElements),
      exhaustMap(({ elements }) =>
        this.elementsAPI.updateElements(elements).pipe(
          map(elements => updateElementsSuccess({ elements })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteElement),
      exhaustMap(({ id }) =>
        this.elementsAPI.deleteElement(id).pipe(
          map(() => deleteElementSuccess()),
          catchError(() => EMPTY)
        )
      )
    )
  );
}
