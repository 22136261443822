import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  createSchemeType,
  createSchemeTypeSuccess,
  deleteSchemeType,
  deleteSchemeTypeSuccess,
  getAllSchemeTypes,
  getAllSchemeTypesSuccess,
  getSchemeType,
  getSchemeTypeSuccess,
  updateSchemeType,
  updateSchemeTypeSuccess,
} from './scheme-types.action';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';
import { SchemeTypesApiService } from '../../api/scheme-types-api.service';

@Injectable()
export class SchemeTypesEffects {
  constructor(
    private actions$: Actions,
    private schemeTypesAPI: SchemeTypesApiService
  ) {}

  getAllSchemeTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllSchemeTypes),
      exhaustMap(() =>
        this.schemeTypesAPI.getAllSchemeTypes().pipe(
          map(schemeTypes => getAllSchemeTypesSuccess({ schemeTypes })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getSchemeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSchemeType),
      exhaustMap(action =>
        this.schemeTypesAPI.getSchemeType(action.id).pipe(
          map(schemeType => getSchemeTypeSuccess({ schemeType })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createSchemeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSchemeType),
      exhaustMap(({ schemeType }) =>
        this.schemeTypesAPI.createSchemeType(schemeType).pipe(
          map(schemeType => createSchemeTypeSuccess({ schemeType })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSchemeType),
      exhaustMap(action =>
        this.schemeTypesAPI.updateSchemeType(action.schemeType).pipe(
          map(schemeType => updateSchemeTypeSuccess({ schemeType })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSchemeType),
      exhaustMap(({ id }) =>
        this.schemeTypesAPI.deleteSchemeType(id).pipe(
          map(() => deleteSchemeTypeSuccess()),
          catchError(() => EMPTY)
        )
      )
    )
  );
}
