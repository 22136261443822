import { APP_INITIALIZER, ApplicationConfig, isDevMode } from '@angular/core';
import { Store } from '@ngrx/store';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import {
  MissingTranslationHandler,
  provideTranslateService,
  TranslateLoader,
} from '@ngx-translate/core';

import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { doesCompanyUserExists } from './login/utils/does-company-user-info-exists';
import { authInterceptor } from './core/interceptors/auth.interceptor';
import { storeImports } from './app.store';
import { routes } from './app.routes';
import { OnboardrMissingTranslationHandler, TranslateHttpLoader } from './app.translations';

// https://angular.io/api/core/APP_INITIALIZER
export function initializeApp(store: Store) {
  doesCompanyUserExists(store);
  return () => true;
}

export const appConfig: ApplicationConfig = {
  providers: [
    ...storeImports,
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    provideTranslateService({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: OnboardrMissingTranslationHandler,
      },
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHttpLoader,
        deps: [HttpClient],
      },
    }),
    provideMomentDateAdapter(),
    provideAnimations(),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideAngularSvgIcon(),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode(), name: 'OnBoardR' }),
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [Store],
    },
  ],
};
