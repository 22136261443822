import { RouteMap } from './route-map.model';

export interface RouteMapState {
  routeMaps: RouteMap[];
  routeMap: RouteMap;
  loadingRouteMaps: boolean;
  loadingRouteMap: boolean;
  routeMapUpdated: boolean;
  routeMapDeleted: boolean;
}

export const emptyRouteMap: RouteMap = {
  name: '',
  description: '',
  selectedSchemes: [],
  routeMapSchemes: [],
  steps: [],
};

export const initialRouteMapState: RouteMapState = {
  routeMaps: [],
  routeMap: emptyRouteMap,
  loadingRouteMaps: false,
  loadingRouteMap: false,
  routeMapUpdated: false,
  routeMapDeleted: false,
};
