import { createReducer, on } from "@ngrx/store";
import { emptyUser, initialUserState } from "./user.state";
import { createUserError, createUserSuccess, deleteUserSuccess, getAllUsers, getAllUsersSuccess, getUser, getUserSuccess, resetUserDeleted, resetUserError, resetUserUpdated, updateUserPasswordSuccess, updateUserSuccess } from "./user.action";

export const UserReducer = createReducer(
    initialUserState,
    on(getAllUsers, state => ({ ...state, loadingUsers: true })),
    on(getAllUsersSuccess, (state, { users }) => ({
      ...state,
      users: users,
      loadingUsers: false,
    })),
    on(getUser, state => ({ ...state, loadingUser: true })),
    on(getUserSuccess, (state, { user }) => ({ ...state, user, loadingUser: false })),
    on(createUserSuccess, (state, { user }) => ({
      ...state,
      user,
      userUpdated: true,
    })),
    on(createUserError, (state, {error}) => ({ ...state, error: error.error.error})),
    on(resetUserError, state => ({ ...state, error: ''})),
    on(updateUserSuccess, (state, { user }) => ({
      ...state,
      user,
      userUpdated: true,
    })),
    on(updateUserPasswordSuccess, state => ({
      ...state,
      userUpdated: true,
    })),
    on(resetUserUpdated, state => ({ ...state, userUpdated: false })),
    on(deleteUserSuccess, state => ({ ...state, user: emptyUser, userDeleted: true })),
    on(resetUserDeleted, state => ({ ...state, userDeleted: false }))
);
