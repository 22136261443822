import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  createSchemeType,
  createSchemeTypeSuccess,
  createSchemeTypeWithElements,
  deleteSchemeType,
  deleteSchemeTypeSuccess,
  getAllSchemeTypes,
  getAllSchemeTypesSuccess,
  getSchemeType,
  getSchemeTypeSuccess,
  updateSchemeType,
  updateSchemeTypeSuccess,
} from './scheme-types.action';
import { EMPTY, catchError, concatMap, map, switchMap } from 'rxjs';
import { SchemeTypesApiService } from '../../api/scheme-types-api.service';
import { ElementsApiService } from '../../api/elements-api.service';

@Injectable()
export class SchemeTypesEffects {
  constructor(
    private actions$: Actions,
    private schemeTypesAPI: SchemeTypesApiService,
    private elementsAPI: ElementsApiService
  ) {}

  getAllSchemeTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllSchemeTypes),
      switchMap(() =>
        this.schemeTypesAPI.getAllSchemeTypes().pipe(
          map(schemeTypes => getAllSchemeTypesSuccess({ schemeTypes })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getSchemeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSchemeType),
      switchMap(action =>
        this.schemeTypesAPI.getSchemeType(action.id).pipe(
          map(schemeType => getSchemeTypeSuccess({ schemeType })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createSchemeType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSchemeType),
      switchMap(({ schemeType }) =>
        this.schemeTypesAPI.createSchemeType(schemeType).pipe(
          map(schemeType => createSchemeTypeSuccess({ schemeType })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createSchemeTypeWithElements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSchemeTypeWithElements),
      switchMap(schemeTypeWithElements => {
        const { schemeType, elements } = schemeTypeWithElements;

        return this.schemeTypesAPI.createSchemeType(schemeType).pipe(
          concatMap(schemeType => {
            const createdSchemeTypeElements = elements.map(el => ({
              ...el,
              id: null,
              mapId: null,
              schemeType: schemeType,
              schemeTypeId: schemeType.id,
            }));

            return this.elementsAPI.createElement(createdSchemeTypeElements).pipe(
              map(elements => {
                return createSchemeTypeSuccess({ schemeType });
              })
            );
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  updateScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSchemeType),
      switchMap(action =>
        this.schemeTypesAPI.updateSchemeType(action.schemeType).pipe(
          map(schemeType => updateSchemeTypeSuccess({ schemeType })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSchemeType),
      switchMap(({ id }) =>
        this.schemeTypesAPI.deleteSchemeType(id).pipe(
          map(() => deleteSchemeTypeSuccess()),
          catchError(() => EMPTY)
        )
      )
    )
  );
}
