import { createReducer, on } from '@ngrx/store';
import { initialAdminState } from './admin.state';
import { GetAllAdminMapsAction, GetAllAdminMapsSuccessAction } from './admin.actions';

export const adminReducer = createReducer(
  initialAdminState,
  on(GetAllAdminMapsAction, state => ({ ...state, loadingAdminMaps: true })),
  on(GetAllAdminMapsSuccessAction, (state, { adminMaps }) => ({
    ...state,
    adminMaps,
    loadingAdminMaps: false,
  }))
);
