import { createAction, props } from "@ngrx/store";
import { Constraint } from "./constraints.model";

export enum ConstraintActionType {
  GET_ALL_CONSTRAINTS = '[Constraint] Get All Constraints',
  GET_ALL_CONSTRAINTS_SUCCESS = '[Constraint] Get All Constraints :: Success'
}

export const getAllConstraints = createAction(ConstraintActionType.GET_ALL_CONSTRAINTS);
export const getAllConstraintsSuccess = createAction(
  ConstraintActionType.GET_ALL_CONSTRAINTS_SUCCESS,
  props<{ constraints: Constraint[] }>()
);
