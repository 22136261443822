import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, catchError, exhaustMap, map } from "rxjs";
import { ContactApiService } from "../../api/contact-api.service";
import { getAllContacts, getAllContactsSuccess } from "./contact.action";

@Injectable()
export class ContactEffects {
  getAllContacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllContacts),
      exhaustMap(() =>
        this.contactAPI.getAllContacts().pipe(
          map(contacts => getAllContactsSuccess({contacts})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private contactAPI: ContactApiService
  ) {}
}
