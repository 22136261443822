import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouteMapApiService } from '../../api/route-map-api.service';
import { createRouteMap, createRouteMapSuccess, deleteRouteMap, deleteRouteMapSuccess, getAllRouteMaps, getAllRouteMapsSuccess, getRouteMap, getRouteMapSuccess, updateRouteMap, updateRouteMapSuccess } from './route-map.actions';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';

@Injectable()
export class RouteMapEffects {
  getAllRouteMaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllRouteMaps),
      exhaustMap(() =>
        this.routeMapAPI.getAllRouteMaps().pipe(
          map(routeMaps => getAllRouteMapsSuccess({routeMaps})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getRouteMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRouteMap),
      exhaustMap((action) =>
        this.routeMapAPI.getRouteMap(Number(action.id)).pipe(
          map(routeMap => getRouteMapSuccess({routeMap})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createRouteMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRouteMap),
      exhaustMap(action =>
        this.routeMapAPI.createRouteMap(action.routeMap).pipe(
          map(routeMap => createRouteMapSuccess({routeMap})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRouteMap),
      exhaustMap((action) =>
        this.routeMapAPI.updateRouteMap(action.routeMap).pipe(
          map(routeMap => updateRouteMapSuccess({routeMap})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRouteMap),
      exhaustMap((action) =>
        this.routeMapAPI.deleteRouteMap(action.id).pipe(
          map(() => deleteRouteMapSuccess()),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private routeMapAPI: RouteMapApiService
  ) {}
}
