import { Element } from './element.model';

export interface ElementState {
  elements: Element[];
  element: Element | Element[];
  loadingElements: boolean;
  loadingElement: boolean;
  elementUpdated: boolean;
  elementDeleted: boolean;
}

export const emptyElement: Element = {
  defaultLabel: '',
  defaultValue: {},
  mapId: null,
  elementType: '',
  level: null,
  order: null,
  schemeType: null,
  width: null,
  schemeTypeId: null
};

export const initialElementState: ElementState = {
  elements: [],
  element: emptyElement,
  loadingElements: false,
  loadingElement: false,
  elementUpdated: false,
  elementDeleted: false,
};
