import { Store } from '@ngrx/store';
import { UserInfo } from '../../core/store/company/company.model';
import { AUTH_VARIABLES } from './auth-variables';
import { getLocalStorage } from './local-storage-utils';
import { setUserInfo } from '../../core/store/company/company.action';

export const doesCompanyUserExists = (store: Store) => {
  const { createByEmail, createByName } =
    JSON.parse(getLocalStorage(AUTH_VARIABLES.companyUserInfo)) ?? ({} as UserInfo);

  if (!createByEmail && !createByName) return;

  store.dispatch(
    setUserInfo({
      userInfo: {
        createByEmail,
        createByName,
      },
    })
  );
};
