import { createReducer, on } from '@ngrx/store';
import { emptyProgram, initialProgramState } from './program.state';
import {
  createProgramSuccess,
  deleteProgramSuccess,
  getAllPrograms,
  getAllProgramsSuccess,
  getProgram,
  getProgramSuccess,
  resetProgramDeleted,
  resetProgramUpdated,
  updateProgramSuccess,
} from './program.actions';

export const programReducer = createReducer(
  initialProgramState,
  on(getAllPrograms, state => ({ ...state, loadingPrograms: true })),
  on(getAllProgramsSuccess, (state, { programs }) => ({
    ...state,
    loadingPrograms: false,
    programs: programs,
  })),
  on(getProgram, state => ({ ...state, loadingProgram: true })),
  on(getProgramSuccess, (state, { program }) => ({
    ...state,
    program: program,
    loadingProgram: false,
  })),
  on(createProgramSuccess, (state, { program }) => ({
    ...state,
    program: program,
    programUpdated: true,
  })),
  on(updateProgramSuccess, (state, { program }) => ({
    ...state,
    program: program,
    programUpdated: true,
  })),
  on(resetProgramUpdated, state => ({ ...state, programUpdated: false })),
  on(deleteProgramSuccess, state => ({ ...state, program: emptyProgram, programDeleted: true })),
  on(resetProgramDeleted, state => ({ ...state, programDeleted: false }))
);
