import { createAction, props } from "@ngrx/store";
import { Origin } from "./origin.model";

export enum OriginActionType {
  GET_ALL_ORIGINS = '[Origin] Get All Origins',
  GET_ALL_ORIGINS_SUCCESS = '[Origin] Get All Origins :: Success'
}

export const getAllOrigins = createAction(OriginActionType.GET_ALL_ORIGINS);
export const getAllOriginsSuccess = createAction(
  OriginActionType.GET_ALL_ORIGINS_SUCCESS,
  props<{ origins: Origin[] }>()
);
