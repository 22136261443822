import { createAction, props } from '@ngrx/store';
import { ElementValue } from './element-value.model';

const elementValuesActionLabel = '[Element-Values]';

export enum ElementValuesActionType {
  GET_ALL_ELEMENT_VALUES = `${elementValuesActionLabel} Get All Element Values`,
  GET_ALL_ELEMENTS_VALUES_SUCCESS = `${elementValuesActionLabel} Get All Element Values :: Success`,
  GET_ELEMENT_VALUE = `${elementValuesActionLabel} Get Element Values`,
  GET_ELEMENT_VALUE_SUCCESS = `${elementValuesActionLabel} Get Element Values :: Success`,
  CREATE_ELEMENT_VALUE = `${elementValuesActionLabel} Create Element Values`,
  CREATE_ELEMENT_VALUE_SUCCESS = `${elementValuesActionLabel} Create Element Values :: Success`,
  UPDATE_ELEMENT_VALUE = `${elementValuesActionLabel} Update Element Values`,
  UPDATE_ELEMENT_VALUE_SUCCESS = `${elementValuesActionLabel} Update Element Values :: Success`,
  DELETE_ELEMENT_VALUE = `${elementValuesActionLabel} Delete Element Values`,
  DELETE_ELEMENT_VALUE_SUCCESS = `${elementValuesActionLabel} Delete Element Values :: Success`,
  RESET_ELEMENT_VALUE_UPDATED = `${elementValuesActionLabel} Reset Element Values Updated`,
  RESET_ELEMENT_VALUE_DELETED = `${elementValuesActionLabel} Reset Element Values Deleted`,
}

export const getAllElementValues = createAction(ElementValuesActionType.GET_ALL_ELEMENT_VALUES);
export const getAllElementValuesSuccess = createAction(
  ElementValuesActionType.GET_ALL_ELEMENTS_VALUES_SUCCESS,
  props<{ elementValues: ElementValue[] }>()
);
export const getElementValue = createAction(
  ElementValuesActionType.GET_ELEMENT_VALUE,
  props<{ id: number }>()
);
export const getElementValueSuccess = createAction(
  ElementValuesActionType.GET_ELEMENT_VALUE,
  props<{ elementValue: ElementValue }>()
);
export const createElementValue = createAction(
  ElementValuesActionType.CREATE_ELEMENT_VALUE,
  props<{ elementValue: ElementValue }>()
);
export const createElementValueSuccess = createAction(
  ElementValuesActionType.CREATE_ELEMENT_VALUE_SUCCESS,
  props<{ elementValue: ElementValue }>()
);
export const updateElementValue = createAction(
  ElementValuesActionType.UPDATE_ELEMENT_VALUE,
  props<{ elementValue: ElementValue }>()
);
export const updateElementValueSuccess = createAction(
  ElementValuesActionType.UPDATE_ELEMENT_VALUE_SUCCESS,
  props<{ elementValue: ElementValue }>()
);
export const deleteElementValue = createAction(
  ElementValuesActionType.DELETE_ELEMENT_VALUE,
  props<{ id: number }>()
);
export const deleteElementValueSuccess = createAction(
  ElementValuesActionType.DELETE_ELEMENT_VALUE_SUCCESS
);
export const resetElementValuesUpdated = createAction(
  ElementValuesActionType.RESET_ELEMENT_VALUE_UPDATED
);
export const resetElementValuesDeleted = createAction(
  ElementValuesActionType.RESET_ELEMENT_VALUE_DELETED
);
