import { Routes } from '@angular/router';
import { DashboardLayoutComponent } from './shared/layouts/dashboard-layout/dashboard-layout.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout/login-layout.component';
import { authGuard } from './core/guards/auth.guard';
import { loginGuard } from './core/guards/login.guard';
import { roleGuard } from './core/guards/role.guard';
import { ROLE_ADMIN, ROLE_USER } from './core/utils/roles';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginLayoutComponent,
    canActivate: [loginGuard],
    children: [
      { path: '', loadChildren: () => import('./login/login.routes').then(r => r.LOGIN_ROUTES) },
    ],
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'companies',
        canActivate: [roleGuard],
        data: { roles: [ROLE_USER, ROLE_ADMIN] },
        loadChildren: () => import('./company/company.routes').then(r => r.COMPANY_ROUTES),
      },
      {
        path: 'admin',
        canActivate: [roleGuard],
        data: { roles: [ROLE_ADMIN] },
        loadChildren: () => import('./admin/admin.routes').then(r => r.ADMIN_ROUTES),
      },
      { path: '**', redirectTo: 'companies' },
    ],
  },
  { path: '**', redirectTo: '/login' },
];
