{
  "adminDashboard": {
    "tabs": {
      "programs": "Programs",
      "companyMaps": "Company Maps",
      "originMaps": "Origin Maps",
      "processFlow": "Process Flow Maps",
      "schemeTypes": "Scheme Types",
      "schemes": "Schemes",
      "inductionLocations": "Induction Locations",
      "users": "Users",
      "extras": "Extras"
    },
    "programs": {
      "createProgramButtonLabel": "Create Program",
      "deleteProgramButtonLabel": "Delete Program",
      "saveProgramButtonLabel": "Save Program",
      "selectAllCountriesButtonLabel": "Select All",
      "removeAllCountriesButtonLabel": "Remove All",
      "programNameInput": {
        "label": "Program Name",
        "placeholder": "Type the Program name..."
      },
      "countriesDropdown": {
        "label": "Countries",
        "placeholder": "Add Country..."
      },
      "companyMapDropdown": {
        "label": "Company Map",
        "requiredError": "The Company map is required"
      },
      "originMapDropdown": {
        "label": "Origin Map",
        "requiredError": "The Origin map is required"
      },
      "processFlowDropdown": {
        "label": "Process Flow Map",
        "requiredError": "The Process Flow Map is required"
      },
      "programNarrativeTextarea": {
        "label": "Program Narrative",
        "placeholder": "Write the program's narrative"
      },
      "shipMethods": {
        "sectionTitle": "Ship Methods",
        "defaultShippingMethodsLabel": "Default",
        "additionalShippingMethodsLabel": "Additional Ship Methods",
        "addAdditionalDeliveryRoutesCheckboxLabel": "Add additional delivery routes",
        "additionalDeliveryRoutes": {
          "label": "Additional Delivery Routes Name",
          "requiredError": "The name is required"
        }
      },
      "createProgramDialog": {
        "title": "Create Program",
        "description": "Set the program name",
        "submitButtonLabel": "Create Program",
        "programNameInput": {
          "label": "Program Name",
          "placeholder": "Type the Program name..."
        }
      },
      "deleteProgramDialog": {
        "title": "Delete Program",
        "description": "Are you sure you want to delete the program: {{ programName }}?",
        "submitButtonLabel": "Delete Program"
      },
      "processFlowPreviewDialog": {
        "emptyStepsText": "This Process Flow doesn't have steps defined",
        "closeDialogButtonText": "Close"
      },
      "saveProgramErrorText": "Please select at least one delivery agent of the Ship Methods"
    },
    "companyMaps": {
      "createCompanyMapButtonLabel": "Create Company Map",
      "deleteCompanyMapButtonLabel": "Delete Company Map",
      "saveCompanyMapButtonLabel": "Save Company Map",
      "companyMapInput": {
        "label": "Company Map Name",
        "subtitle": "Company Map Name"
      },
      "createCompanyMapDialog": {
        "title": "Create Company Map",
        "description": "",
        "submitButtonText": "Create Company Map",
        "cancelButtonText": "Cancel",
        "companyMapNameInput": {
          "label": "Company Map Name",
          "placeholder": "Type the company map name..."
        }
      },
      "deleteCompanyMapDialog": {
        "title": "Delete {{ companyMapName }}",
        "description": "Are you sure you want to delete this company map?",
        "submitButtonText": "Delete Company Map",
        "cancelButtonText": "Cancel"
      },
      "unsavedChangesDialog": {
        "title": "Unsaved Changes",
        "description": "You have unsaved changes. Are you sure you want to leave without saving?",
        "cancelButtonText": "Discard changes",
        "saveChangesButtonText": "Save changes"
      },
      "notConfiguredConstraintsErrorText": "Please configure at least one constraint"
    },
    "originMaps": {
      "createOriginMapButtonLabel": "Create Origin Map",
      "deleteOriginMapButtonLabel": "Delete Origin Map",
      "saveOriginMapButtonLabel": "Save Origin Map",
      "isRestOfWorldToggleLabel": "Is Rest of World",
      "originMapInput": {
        "label": "Origin Map Name",
        "subtitle": "Origin Map Name"
      },
      "createOriginMapDialog": {
        "title": "Create Origin Map",
        "description": "",
        "submitButtonText": "Create Origin Map",
        "cancelButtonText": "Cancel",
        "originMapNameInput": {
          "label": "Origin Map Name",
          "placeholder": "Type the origin map name..."
        }
      },
      "deleteOriginMapDialog": {
        "title": "Delete {{ originMapName }}",
        "description": "Are you sure you want to delete this origin map?",
        "submitButtonText": "Delete Origin Map",
        "cancelButtonText": "Cancel"
      },
      "unsavedChangesDialog": {
        "title": "Unsaved Changes",
        "description": "You have unsaved changes. Are you sure you want to leave without saving?",
        "cancelButtonText": "Discard changes",
        "saveChangesButtonText": "Save changes"
      },
      "notConfiguredConstraintsErrorText": "Please configure at least one constraint"
    },
    "mapConstraintsTable": {
      "tooltipHasConditionalLogicText": "Has conditional logic configured",
      "constraintsMoreOptionsTooltipText": "See more options",
      "columns": {
        "element": "Element",
        "type": "Type",
        "options": "Options",
        "actions": "Actions",
        "delete": "Delete"
      },
      "noElementsDefinedText": "This Scheme Type does not have elements defined.",
      "goToSchemeTypeTabText": "Go to the Scheme Types tab and create some for this Scheme Type.",
      "constraints": {
        "checkedCheckbox": {
          "label": "Checked",
          "tooltip": "If enabled, marks the field as checked"
        },
        "hiddenCheckbox": {
          "label": "Hidden",
          "tooltip": "Hides the field if checked"
        },
        "disabledCheckbox": {
          "label": "Disabled",
          "tooltip": "Disables the field"
        },
        "requiredCheckbox": {
          "label": "Required",
          "tooltip": "Marks the field as required"
        },
        "multipleCheckbox": {
          "label": "Multiple",
          "tooltip": "Allows multiple selection of options"
        },
        "labelInput": {
          "label": "Label",
          "placeholder": "Type the label..."
        },
        "defaultValueInput": {
          "label": "Default Value",
          "placeholder": "Type the value..."
        },
        "maskInput": {
          "label": "Mask",
          "placeholder": "Type the pattern..."
        },
        "maxLengthInput": {
          "label": "Max Length",
          "placeholder": "Set the max length"
        },
        "minLengthInput": {
          "label": "Max Length",
          "placeholder": "Set the min length"
        },
        "defaultOptionDropdown": {
          "label": "Default Option",
          "noOptionSelected": "[None]",
          "noOptionsAvailable": "Please add options"
        },
        "addDropdownOptionsDialog": {
          "optionsButtonText": "Options",
          "optionsDropdown": {
            "label": "Options",
            "placeholder": "Add Option..."
          },
          "description": "Add the options for the dropdown. Each option must be separated by commas.",
          "removeAllOptionsButtonText": "Remove All",
          "cancelButtonText": "Cancel",
          "saveChangesButtonText": "Save Changes"
        },
        "conditionalLogicDialog": {
          "openDialogButtonTooltipText": "Configure Conditionals for this field",
          "dialogTitle": "Configure \"{{ elementLabel }}\" Conditions",
          "hideFieldDescription": "Hide \"{{ elementLabel }}\" if:",
          "emptyElementFieldsDescription": "Add Elements to create field conditions that will hide the \"{{ elementLabel }}\" field",
          "elementIdDropdown": {
            "label": "Element Field",
            "placeholder": "Select an element..."
          },
          "elementIdSearchInput": {
            "placeholder": "Search for element...",
            "emptyElements": "No elements found"
          },
          "inputConditionText": "includes",
          "inputLabelText": "Value",
          "textareaConditionText": "includes",
          "textareaLabelText": "Value",
          "selectConditionText": "has selected",
          "buttonGroupConditionText": "has selected",
          "dateConditionText": "has selected",
          "listConditionText": "includes one of",
          "defaultConditionMessage": "Select an Element",
          "addElementFieldButtonText": "Add Element Field"
        }
      }
    },
    "processFlow": {
      "createProcessFlowButtonLabel": "Create Process Flow",
      "deleteProcessFlowButtonLabel": "Delete Process Flow",
      "saveProcessFlowButtonLabel": "Save Process Flow",
      "processFlowStepsSectionText": "Process Flow Steps",
      "processFlowNameInput": {
        "label": "Process Flow Name",
        "placeholder": "Type the process flow name..."
      },
      "processFlowDescriptionInput": {
        "label": "Process Flow Description",
        "placeholder": "Type the process flow description..."
      },
      "createProcessFlowDialog": {
        "title": "Create Process Flow",
        "description": "Set the process flow name",
        "submitButtonText": "Create Process Flow",
        "cancelButtonText": "Cancel",
        "processFlowNameInput": {
          "label": "Process Flow Name",
          "placeholder": "Type the process flow name..."
        }
      },
      "deleteProcessFlowDialog": {
        "title": "Delete {{ processFlowName }} ",
        "description": "Are you sure you want to delete this process flow?",
        "submitButtonText": "Delete Process Flow",
        "cancelButtonText": "Cancel"
      },
      "processFlowCollapsibleSchemes": {
        "stepName": {
          "label": "Step",
          "requiredError": "Name is required"
        },
        "stepDescription": {
          "label": "Description",
          "placeholder": "Step description...",
          "requiredError": "Name is required"
        },
        "schemesTable": {
          "availableColumn": "Available",
          "schemeColumn": "Scheme"
        },
        "dragPreview": {
          "step": "Step:"
        },
        "addSchemeTypeDropdown": {
          "label": "Add Scheme Type",
          "placeholder": "Select the Scheme Type to be added..."
        },
        "addStepButtonText": "Add Step",
        "emptySchemeTypesText": "Add or move a Scheme Type here",
        "defaultStepName": "Step"
      }
    },
    "schemeTypes": {
      "createSchemeTypeButtonLabel": "Create Scheme Type",
      "schemeTypeNameInput": {
        "label": "Scheme Type Name",
        "placeholder": "Type the Scheme Type Name..."
      },
      "deleteSchemeTypeButtonLabel": "Delete Scheme Type",
      "saveSchemeTypeButtonLabel": "Save Scheme Type",
      "schemeTypeOrderInput": {
        "label": "Scheme Type Order",
        "placeholder": "Set the scheme type order..."
      },
      "deleteSchemeTypeWarningTooltipText": "Delete the Schemes of this Scheme Type first",
      "createSchemeNoticeText": "Create the first Scheme Type to start adding elements"
    },
    "elements": {
      "addElementButtonLabel": "Add Element",
      "saveElementsButtonLabel": "Save Elements",
      "tableColumns": {
        "defaultLabel": {
          "label": "Default Label",
          "placeholder": "Type the field label...",
          "requiredError": "This field is required"
        },
        "elementType": {
          "label": "Element Type",
          "placeholder": "Type the field label...",
          "requiredError": "This field is required"
        },
        "order": {
          "label": "Order"
        },
        "width": {
          "label": "Width"
        },
        "actions": {
          "label": "Actions"
        }
      },
      "elementTypes": {
        "buttonGroup": "Button Set",
        "checkbox": "Checkbox",
        "select": "Dropdown",
        "textarea": "Text Area",
        "textbox": "Text",
        "header": "Section Header",
        "datepicker": "Date",
        "list": "List"
      },
      "unsavedChangesDialog": {
        "title": "Unsaved Changes",
        "description": "You have unsaved changes. Are you sure you want to leave without saving?",
        "cancelButtonText": "Discard changes",
        "saveChangesButtonText": "Save changes"
      },
      "createSchemeTypeDialog": {
        "title": "Create Scheme Type",
        "description": "Set the scheme type name",
        "submitButtonText": "Create Scheme Type",
        "cancelButtonText": "Cancel",
        "schemeTypeNameInput": {
          "label": "Scheme Type Name",
          "placeholder": "Type the scheme type name..."
        },
        "schemeCopyFromDropdown": {
          "label": "Copy from Scheme Type",
          "placeholder": "Select the Scheme Type to copy from..."
        }
      },
      "deleteSchemeTypeDialog": {
        "title": "Delete {{ schemeTypeName }}",
        "description": "Are you sure you want to delete this scheme type?",
        "submitButtonText": "Delete Scheme Type",
        "cancelButtonText": "Cancel"
      },
      "invalidElementsErrorSnackbarMessage": "Please fill all the required fields"
    },
    "adminExtras": {
      "sections": {
        "downloadDatabaseData": "Download Database Data",
        "downloadCompanyReport": "Download Company Report",
        "translationsEditor": "Translations Editor"
      },
      "downloadDatabaseData": {
        "sectionTitle": "Download Database Table Data",
        "selectTableDropdown": {
          "label": "Select Table",
          "placeholder": "Select the table...",
          "requiredError:": "The table is required"
        },
        "selectFileTypeDropdown": {
          "label": "Select the file type",
          "placeholder": "Select the file type...",
          "requiredError:": "The file type is required"
        },
        "fileNameInput": {
          "label": "File Name",
          "placeholder": "Type the name of the file...",
          "requiredError:": "The file name is required"
        },
        "downloadTableButtonText": "Download File"
      },
      "downloadCompanyReport": {
        "sectionTitle": "Download Company Report",
        "selectCompanyDropdown": {
          "label": "Select Company",
          "placeholder": "Select the Company...",
          "requiredError:": "The company is required"
        },
        "selectFileTypeDropdown": {
          "label": "Select the file type",
          "placeholder": "Select the file type...",
          "requiredError:": "The file type is required"
        },
        "fileNameInput": {
          "label": "File Name",
          "placeholder": "Type the name of the file...",
          "requiredError:": "The file name is required"
        },
        "downloadReportButtonText": "Download File"
      },
      "translationsEditor": {
        "sectionTitle": "Translations Texts Editor",
        "addTranslationButtonText": "Add Translation",
        "saveChangesButtonText": "Save Changes",
        "noTranslationsFoundText": "No translations found",
        "removeTranslationTooltipText": "Remove Translation",
        "translationAddedSnackbarMessage": "Key and value added successfully",
        "addTranslationDialog": {
          "title": "Add Translation Key and Value",
          "description": "Set the translation key and value",
          "submitButtonText": "Add Translation",
          "cancelButtonText": "Cancel",
          "translationKeyInput": {
            "label": "Key",
            "placeholder": "Type the object key..."
          },
          "translationValueInput": {
            "label": "Value",
            "placeholder": "Type the translation value..."
          }
        }
      }
    },
    "inductionLocations": {
      "createInductionLocationButtonLabel": "Create Induction Location",
      "saveInductionLocationButtonLabel": "Save Induction Location",
      "deleteInductionLocationButtonLabel": "Delete Induction Location",
      "inductionLocationInput": {
        "label": "Induction Location Name",
        "placeholder": "Type the induction location name...",
        "requiredError": "This field is required"
      },
      "inductionLocationCodeInput": {
        "label": "Induction Location Code",
        "placeholder": "Type the induction location code...",
        "requiredError": "This field is required"
      },
      "createInductionLocationDialog": {
        "title": "Create Induction Location",
        "description": "Set the Induction location display name and code",
        "submitButtonText": "Create Induction Location",
        "inductionLocationNameInput": {
          "label": "Induction Location Name",
          "placeholder": "Type the induction location name..."
        },
        "inductionLocationCodeInput": {
          "label": "Induction Location Code",
          "placeholder": "Type the induction location code..."
        }
      },
      "deleteInductionLocationDialog": {
        "title": "Delete {{ inductionLocationName }}",
        "description": "Are you sure you want to delete this induction location?",
        "submitButtonText": "Delete Induction Location",
        "cancelButtonText": "Cancel"
      }
    },
    "schemes": {
      "createSchemeButtonLabel": "Create Scheme",
      "deleteSchemeButtonLabel": "Delete Scheme",
      "saveSchemeButtonLabel": "Save Scheme",
      "schemeNameInput": {
        "label": "Scheme Name",
        "placeholder": "Type the scheme name..."
      },
      "schemeTypeDropdown": {
        "label": "Scheme Type",
        "placeholder": "Select the scheme type..."
      },
      "deleteSchemeDialog": {
        "title": "Delete {{ schemeName }}",
        "description": "Are you sure you want to delete this scheme?",
        "submitButtonText": "Delete Scheme",
        "cancelButtonText": "Cancel"
      },
      "unsavedChangesDialog": {
        "title": "Unsaved Changes",
        "description": "You have unsaved changes. Are you sure you want to leave without saving?",
        "cancelButtonText": "Discard changes",
        "saveChangesButtonText": "Save changes"
      },
      "createSchemeDialog": {
        "title": "Create Scheme",
        "description": "Set the scheme name and type",
        "submitButtonText": "Create Scheme",
        "cancelButtonText": "Cancel",
        "schemeNameInput": {
          "label": "Scheme Name",
          "placeholder": "Type the scheme name..."
        },
        "schemeTypeDropdown": {
          "label": "Scheme Type",
          "placeholder": "Select the scheme type..."
        }
      },
      "invalidConstraintsErrorSnackbarMessage": "Please fill all the required fields"
    },
    "users": {
      "createUserButtonLabel": "Create User",
      "deleteUserButtonLabel": "Delete User",
      "saveUserButtonLabel": "Save User",
      "savePasswordButtonLabel": "Save Password",
      "userNameInput": {
        "label": "User Name",
        "placeholder": "Type the user name..."
      },
      "userEmailInput": {
        "label": "User Email",
        "placeholder": "Type the user email..."
      },
      "userPasswordInput": {
        "label": "User Password",
        "placeholder": "Type the user password..."
      },
      "userRoleInput": {
        "label": "User Role",
        "placeholder": "Select the user role..."
      },
      "reEnterPasswordInput": {
        "label": "Re-Enter Password",
        "placeholder": "Re-Enter the user password..."
      },
      "createUserDialog": {
        "title": "Create User",
        "description": "Fill the user name, email, password and role fields to create the user",
        "submitButtonText": "Create User",
        "cancelButtonText": "Cancel",
        "userNameInput": {
          "label": "User Name",
          "placeholder": "Type the user name..."
        },
        "userEmailInput": {
          "label": "User Email",
          "placeholder": "Type the user email..."
        },
        "userPasswordInput": {
          "label": "User Password",
          "placeholder": "Type the user password..."
        },
        "userRoleInput": {
          "label": "User Role",
          "placeholder": "Select the user role..."
        }
      },
      "deleteUserDialog": {
        "title": "Delete {{ userName }}",
        "description": "Are you sure you want to delete this user?",
        "submitButtonText": "Delete User",
        "cancelButtonText": "Cancel"
      }
    }
  },
  "login": {
    "loginSectionTitle": "Sign In",
    "loginForm": {
      "emailInput": {
        "label": "Email",
        "placeholder": "Enter email...",
        "requiredError": "The email is required",
        "emailError": "The email is invalid"
      },
      "passwordInput": {
        "label": "Password",
        "placeholder": "Enter password...",
        "requiredError": "The password is required",
        "minlengthError": "The password must be at least 6 characters long"
      },
      "submitButtonText": "Login"
    },
    "tellWhoYouAreForm": {
      "sectionTitle": "Tell us who you are.",
      "nameInput": {
        "label": "Name",
        "placeholder": "Enter name...",
        "requiredError": "The name is required"
      },
      "emailInput": {
        "label": "Email",
        "placeholder": "Enter email...",
        "requiredError": "The email is required",
        "emailError": "The email is invalid"
      },
      "submitButtonText": "Continue"
    }
  },
  "companies": {
    "companiesTitleSection": "Companies",
    "searchCompanyInput": {
      "label": "Search Company",
      "placeholder": "Search company..."
    },
    "addCompanyButtonLabel": "Add Company",
    "tableColumns": {
      "name": "Name",
      "type": "Type",
      "program": "Program",
      "status": "Status",
      "updated": "Updated"
    }
  },
  "company": {
    "createCompanyLabel": "Create Company",
    "goBack": "Go Back",
    "nextButtonLabel": "Next",
    "previousButtonLabel": "Previous",
    "finishButtonLabel": "Finish",
    "nextOriginButtonLabel": "Next Origin",
    "nextDeliveryRouteButtonLabel": "Next Delivery Route",
    "deliveryRouteDefaultName": "Delivery Route {{ index }}",
    "saveAndKeepEditingButton": {
      "label": "Save and Keep Editing",
      "loadingLabel": "Saving..."
    },
    "saveAndKeepEditingOptions": {
      "saveAsDraftText": "Save as Draft",
      "saveAsPublishedText": "Save as Published",
      "deleteCompanyText": "Delete Company"
    },
    "unsavedChangesDialog": {
      "title": "This company may have unsaved changes",
      "description": "You have unsaved changes. Are you sure you want to leave without saving?",
      "cancelText": "Discard changes",
      "continueText": "Save changes"
    },
    "completeInfoDetailsToAddOriginError": "Please complete the company info details in order to add an Origin",
    "deleteCompanyDialog": {
      "title": "This company will be deleted",
      "description": "Are you sure you want to delete this company?",
      "submitButtonText": "Delete Company",
      "cancelButtonText": "Cancel"
    },
    "originShipRoutesSelector": {
      "sectionTitle": "What induction location(s) receive the packages?",
      "defaultShippingMethodsLabel": "Default"
    },
    "formMenu": {
      "companyInfo": "Company Info",
      "origins": "Origins",
      "companyMissingRequiredFields": {
        "contactsMissingRequiredFields": "Please fill the contacts' missing fields",
        "requiredMissingElements": "The following required fields are missing",
        "companyDetailsContactsMissing": "Company Details Contacts required fields are missing",
        "missingShippingFromOption": "Please select an option from the Shipping From section",
        "missingProgram": "Please select a Program"
      },
      "originMissingRequiredFields": {
        "requiredMissingFieldsSection": "The following required fields are missing",
        "requiredMissingFieldsRoutes": "Please fill the missing required fields in the delivery routes",
        "requiredMissingFieldsRoute": "Please fill the missing required fields in the delivery route",
        "requiredMissingFieldsContacts": "Please fill the contacts' missing fields",
        "requiredMissingElements": "The following required fields are missing",
        "requiredMissingFieldsRoutesSection": "Delivery routes are missing required fields",
        "requiredMissingFieldsOriginContacts": "Origin Contacts required fields are missing"
      },
      "routeMissingRequiredFields": {
        "requiredMissingFieldsSection": "The following required fields are missing"
      }
    },
    "formElements": {
      "contactsForm": {
        "contact": "Contact",
        "addContactButtonText": "Add Contact",
        "name": {
          "label": "Name",
          "placeholder": "Type the name..."
        },
        "type": {
          "label": "Type",
          "placeholder": "Select the type..."
        },
        "email": {
          "label": "Email",
          "placeholder": "Type the email..."
        },
        "countryCode": {
          "label": "Country Code",
          "placeholder": "+123..."
        },
        "phone": {
          "label": "Phone",
          "placeholder": "12345678..."
        },
        "address": {
          "label": "Address",
          "placeholder": "Type the address..."
        },
        "city": {
          "label": "City",
          "placeholder": "Type the city..."
        }
      },
      "nonDynamicElements": {
        "headquarters": {
          "label": "Headquarters Location"
        },
        "shippingFrom": {
          "label": "Shipping From"
        },
        "program": {
          "label": "Select a Program",
          "noDescriptionText": "No description",
          "noProgramsAvailable": "No programs available"
        },
        "companyDetails": {
          "label": "Company Details"
        }
      },
      "narrativeError": ">Enter what the company wants to accomplish",
      "requiredError": "This is required",
      "requiredSymbol": "*",
      "invalidElementError": "Error with {{ defaultLabel }}, please check element type."
    },
    "changeHqLocationDialog": {
      "title": "Changing the Headquarters Location will delete all origins and routes",
      "description": "Are you sure you want to change the current Headquarters Location?",
      "cancelButtonText": "Cancel",
      "confirmButtonText": "Change Headquarters Location"
    },
    "changeProgramDialog": {
      "title": "Changing the current Program will delete all origins and routes",
      "description": "Are you sure you want to change the current Program?",
      "cancelButtonText": "Cancel",
      "confirmButtonText": "Change Program"
    },
    "originForm": {
      "sectionTitle": "Add Origins",
      "sectionSubtitleRestOfWorld": "Which countries does the company ship from?",
      "sectionSubtitleNotRestOfWorld": "Which location(s) does the company ship from?",
      "countryInput": {
        "label": "Country",
        "placeholder": "Select a country...",
        "requiredError": "This is required"
      },
      "originNameInput": {
        "label": "Origin Name",
        "placeholder": "Type the origin name...",
        "requiredError": "This is required"
      },
      "addOriginButtonText": "Add Origin",
      "addOriginError": "Please select an origin",
      "airportsSectionTitle": "What are the possible dispatch airports from {{ country }}?",
      "airportInput": {
        "label": "Add Airport Codes",
        "placeholder": "Add new airport...",
        "requiredError": "This is required"
      },
      "addOriginContactButtonText": "Add {{ originName }} Contact",
      "removeOriginTooltipText": "Remove Origin",
      "addAirportButtonText": "Add Airport",
      "addAirportError": "Please select an airport",
      "originCountryChangeDialog": {
        "title": "Changing the origin country will delete all routes",
        "description": "Are you sure you want to change the origin country?",
        "cancelButtonText": "Cancel",
        "confirmButtonText": "Change Country"
      }
    },
    "routeForm": {
      "inductionLocationLabel": "Induction Location",
      "deliveryAgentLabel": "Delivery Agent",
      "copyFromDropdown": {
        "label": "Copy From",
        "placeholder": "Select a route to copy from...",
        "tooltip": "No routes available to copy from"
      },
      "selectSchemeDropdown": {
        "label": "Select Scheme",
        "placeholder": "Select Scheme...",
        "requiredError": "This is required"
      },
      "schemeForm": {
        "sectionTitle": "Please describe Company's {{ schemeName }} requirements"
      },
      "stepNoSchemesDefinedError": "The step doesn't have Scheme Types defined",
      "programNoStepsDefinedError": "The selected Program doesn't have steps defined"
    }
  }
}
