import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MapApiService } from '../../api/map-api.service';
import { MapActionType, createMap, createMapSuccess } from './map.actions';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';

@Injectable()
export class MapEffects {
  getAllMaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActionType.GET_ALL_MAPS),
      exhaustMap(() =>
        this.mapAPI.getAllMaps().pipe(
          map(maps => ({
            type: MapActionType.GET_ALL_MAPS_SUCCESS,
            maps: maps,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActionType.GET_MAP),
      exhaustMap((action: any) =>
        this.mapAPI.getMap(action.id).pipe(
          map(map => ({
            type: MapActionType.GET_MAP_SUCCESS,
            map: map,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMap),
      exhaustMap(action =>
        this.mapAPI.createMap(action.map).pipe(
          map(map => createMapSuccess({ map })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActionType.UPDATE_MAP),
      exhaustMap((action: any) =>
        this.mapAPI.updateMap(action.map).pipe(
          map(map => ({
            type: MapActionType.UPDATE_MAP_SUCCESS,
            map: map,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActionType.DELETE_MAP),
      exhaustMap((action: any) =>
        this.mapAPI.deleteMap(action.id).pipe(
          map(() => ({ type: MapActionType.DELETE_MAP_SUCCESS })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private mapAPI: MapApiService
  ) {}
}
