import { createReducer, on } from '@ngrx/store';
import { emptyMap, initialMapState } from './map.state';
import {
  createMapSuccess,
  deleteMapSuccess,
  getAllMaps,
  getAllMapsSuccess,
  getMap,
  getMapSuccess,
  resetMapDeleted,
  resetMapUpdated,
  updateMapSuccess,
} from './map.actions';

export const mapReducer = createReducer(
  initialMapState,
  on(getAllMaps, state => ({ ...state, loadingMaps: true })),
  on(getAllMapsSuccess, (state, { maps }) => ({
    ...state,
    maps: maps,
    loadingMaps: false,
  })),
  on(getMap, state => ({ ...state, loadingMap: true })),
  on(getMapSuccess, (state, { map }) => ({ ...state, map: map, loadingMap: false })),
  on(createMapSuccess, (state, { map }) => ({
    ...state,
    map: map,
    mapUpdated: true,
  })),
  on(updateMapSuccess, (state, { map }) => ({
    ...state,
    map: map,
    mapUpdated: true,
  })),
  on(resetMapUpdated, state => ({ ...state, mapUpdated: false })),
  on(deleteMapSuccess, state => ({ ...state, map: emptyMap, mapDeleted: true })),
  on(resetMapDeleted, state => ({ ...state, mapDeleted: false }))
);
