import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AddressApiService } from "../../api/address-api.service";
import { EMPTY, catchError, exhaustMap, map } from "rxjs";
import { getAllAddresses, getAllAddressesSuccess } from "./address.action";

@Injectable()
export class AddressEffects {
  getAllAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllAddresses),
      exhaustMap(() =>
        this.addressAPI.getAllAddresses().pipe(
          map(addresses => getAllAddressesSuccess({addresses})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private addressAPI: AddressApiService
  ) {}
}
