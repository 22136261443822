<section class="dashboard-container">
  <section class="dashboard-header">
    <div class="dashboard-logo-container">
      <img src="assets/images/onboardr-logo.svg" alt="Onboardr Logo" />
    </div>
  </section>

  <section class="dashboard-body">
    <aside class="dashboard-sidebar">
      <div class="dashboard-sidebar__links">
        @for (route of dashboardRoutes(); track $index) {
          <div
            class="dashboard-sidebar__link"
            [routerLink]="[route.route]"
            routerLinkActive
            #rla="routerLinkActive">
            <onboardr-button
              buttonType="button"
              buttonSize="small"
              [buttonColor]="rla.isActive ? 'blue-default' : 'simple'"
              customClasses="no-min-width full-width">
              <mat-icon
                class="dashboard-sidebar__icon"
                [ngClass]="{ 'dashboard-sidebar__icon--selected': rla.isActive }"
                [svgIcon]="route.svgIcon"
                [fontSet]="route.fontSet"
                >{{ route.icon }}</mat-icon
              >
              <p>{{ route.label }}</p>
            </onboardr-button>
          </div>
        }
        <div class="dashboard-sidebar__link dashboard-sidebar__link--bottom">
          <onboardr-button
            buttonType="button"
            buttonSize="small"
            [buttonColor]="'simple'"
            customClasses="no-min-width full-width"
            (onClick)="signOut()">
            <mat-icon class="dashboard-sidebar__icon" [svgIcon]="'onboardr-logout'"></mat-icon>
            <p>Logout</p>
          </onboardr-button>
        </div>
      </div>
    </aside>

    <div class="dashboard-content">
      <router-outlet></router-outlet>
    </div>
  </section>
</section>
