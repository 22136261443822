import { ProgramOrigin } from "./program-origin.model";

export interface ProgramOriginState {
  programOrigins: ProgramOrigin[];
}

export const emptyProgramOrigin: ProgramOrigin = {
  programId: null,
  originMapId: null
};

export const initialProgramOriginState: ProgramOriginState = {
  programOrigins: []
};
