import { createReducer, on } from "@ngrx/store";
import { initialRouteState } from "./route.state";
import { getAllRoutesSuccess } from "./route.action";

export const routeReducer = createReducer(
  initialRouteState,
  on(getAllRoutesSuccess, (state, { routes }) => ({
    ...state,
    routes,
  }))
);
