import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CompanyApiService } from '../../api/company-api.service';
import { CompanyActionType } from './company.action';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';

@Injectable()
export class CompanyEffects {
  getAllCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.GET_ALL_COMPANIES),
      exhaustMap(() =>
        this.companyAPI.getAllCompanies().pipe(
          map(companies => ({
            type: CompanyActionType.GET_ALL_COMPANIES_SUCCESS,
            companies: companies,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.GET_COMPANY),
      exhaustMap((action: any) =>
        this.companyAPI.getCompany(action.id).pipe(
          map(company => ({
            type: CompanyActionType.GET_COMPANY_SUCCESS,
            company: company,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.GET_REPORT),
      exhaustMap((action: any) =>
        this.companyAPI.getReport(action.id).pipe(
          map(company => ({
            type: CompanyActionType.GET_REPORT_SUCCESS,
            company: company,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.CREATE_COMPANY),
      exhaustMap((action: any) =>
        this.companyAPI.createCompany(action.company).pipe(
          map(company => ({
            type: CompanyActionType.CREATE_COMPANY_SUCCESS,
            company: company,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.UPDATE_COMPANY),
      exhaustMap((action: any) =>
        this.companyAPI.updateCompany(action.company).pipe(
          map(company => ({
            type: CompanyActionType.UPDATE_COMPANY_SUCCESS,
            company: company,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyActionType.DELETE_COMPANY),
      exhaustMap((action: any) =>
        this.companyAPI.deleteCompany(action.id).pipe(
          map(() => ({ type: CompanyActionType.DELETE_COMPANY_SUCCESS })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private companyAPI: CompanyApiService
  ) {}
}
