import { createReducer, on } from "@ngrx/store";
import { initialAddressState } from "./address.state";
import { getAllAddressesSuccess } from "./address.action";

export const addressReducer = createReducer(
  initialAddressState,
  on(getAllAddressesSuccess, (state, { addresses }) => ({
    ...state,
    addresses,
  }))
);
