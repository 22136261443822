import { emptyAddress } from '../address/address.state';
import { emptyMap } from '../map/map.state';
import { emptyProgram } from '../program/program.state';
import { Company } from './company.model';

export interface CompanyState {
  companies: Company[];
  company: Company;
  loadingCompany: boolean;
  companyUpdated: boolean;
  companyDeleted: boolean;
  createByName: string;
  createByEmail: string;
}

export const emptyCompany: Company = {
  name: '',
  status: '',
  mapId: null,
  map: emptyMap,
  programId: null,
  program: emptyProgram,
  address: emptyAddress,
  contacts: [],
  origins: [],
  createByName: '',
  createByEmail: '',
  creationNarrative: ''
};

export const initialCompanyState: CompanyState = {
  companies: [],
  company: emptyCompany,
  loadingCompany: false,
  companyUpdated: false,
  companyDeleted: false,
  createByName: '',
  createByEmail: ''
};
