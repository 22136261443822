import { InductionLocation } from "./induction-location.model";

export interface InductionLocationState {
    inductionLocations: InductionLocation[];
    inductionLocation: InductionLocation;
    loadingInductionLocations: boolean;
    loadingInductionLocation: boolean;
    inductionLocationUpdated: boolean;
    inductionLocationDeleted: boolean;
  }
  
  export const emptyInductionLocation: InductionLocation = {
    name: '',
    code: ''
  }
  
  export const initialInductionLocationState: InductionLocationState = {
    inductionLocations: [],
    inductionLocation: emptyInductionLocation,
    loadingInductionLocations: false,
    loadingInductionLocation: false,
    inductionLocationUpdated: false,
    inductionLocationDeleted: false
  };