import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../../login/services/authentication.service';

export const loginGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  const isUserLogged = authService.isUserLoggedIn();
  const didUserSayWhoHeIs = authService.didUserSayWhoHeIs();

  if (isUserLogged && didUserSayWhoHeIs) {
    router.navigate(['/dashboard/companies']);
    return false;
  }

  authService.signOut();
  return true;
};
