import { createReducer, on } from "@ngrx/store";
import { initialContactState } from "./contact.state";
import { getAllContactsSuccess } from "./contact.action";

export const contactReducer = createReducer(
  initialContactState,
  on(getAllContactsSuccess, (state, { contacts }) => ({
    ...state,
    contacts,
  }))
);
