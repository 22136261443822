import { Injectable, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { ANGULAR_SVG_ICONS, MATERIAL_SVG_ICONS } from '../utils/icons';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private iconReg = inject(SvgIconRegistryService);
  private domSanitizer = inject(DomSanitizer);
  private matIconRegistry = inject(MatIconRegistry);

  constructor() {}

  loadAngularSvgIcons(): void {
    ANGULAR_SVG_ICONS.forEach(icon => {
      this.iconReg.loadSvg(icon.url, icon.name).subscribe();
    });
  }

  loadMaterialCustomIcons(): void {
    MATERIAL_SVG_ICONS.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
  }
}
