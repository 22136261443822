import { createReducer, on } from "@ngrx/store";
import { initialOriginState } from "./origin.state";
import { getAllOriginsSuccess } from "./origin.action";

export const originReducer = createReducer(
  initialOriginState,
  on(getAllOriginsSuccess, (state, { origins }) => ({
    ...state,
    origins,
  }))
);
