import { Injectable } from "@angular/core";
import { ProgramOriginApiService } from "../../api/program-origin-api.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, catchError, exhaustMap, map } from "rxjs";
import { getAllProgramOrigins, getAllProgramOriginsSuccess } from "./program-origin.action";

@Injectable()
export class ProgramOriginEffects {
  getAllProgramOrigins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllProgramOrigins),
      exhaustMap(() =>
        this.programOriginAPI.getAllProgramOrigins().pipe(
          map(programOrigins => getAllProgramOriginsSuccess({programOrigins})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private programOriginAPI: ProgramOriginApiService
  ) {}
}
