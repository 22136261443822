import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SchemeApiService } from '../../api/scheme-api.service';
import {
  SchemeActionType,
  createScheme,
  createSchemeSuccess,
  deleteScheme,
  deleteSchemeSuccess,
  getSchemeTypesAction,
  getSchemeTypesSuccessAction,
} from './scheme.action';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';

@Injectable()
export class SchemeEffects {
  getAllSchemes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchemeActionType.GET_ALL_SCHEMES),
      exhaustMap(() =>
        this.schemeAPI.getAllSchemes().pipe(
          map(schemes => ({
            type: SchemeActionType.GET_ALL_SCHEMES_SUCCESS,
            schemes: schemes,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchemeActionType.GET_SCHEME),
      exhaustMap((action: any) =>
        this.schemeAPI.getScheme(action.id).pipe(
          map(scheme => ({
            type: SchemeActionType.GET_SCHEME_SUCCESS,
            scheme: scheme,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createScheme),
      exhaustMap(({ scheme }) =>
        this.schemeAPI.createScheme(scheme).pipe(
          map(scheme => createSchemeSuccess({ scheme })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchemeActionType.UPDATE_SCHEME),
      exhaustMap((action: any) =>
        this.schemeAPI.updateScheme(action.scheme).pipe(
          map(scheme => ({
            type: SchemeActionType.UPDATE_SCHEME_SUCCESS,
            scheme: scheme,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteScheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteScheme),
      exhaustMap(({ id }) =>
        this.schemeAPI.deleteScheme(id).pipe(
          map(() => deleteSchemeSuccess()),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private schemeAPI: SchemeApiService
  ) {}
}
