import { createAction, props } from "@ngrx/store";
import { Route } from "./route.model";

export enum RouteActionType {
  GET_ALL_ROUTES = '[Route] Get All Routes',
  GET_ALL_ROUTES_SUCCESS = '[Route] Get All Routes :: Success'
}

export const getAllRoutes = createAction(RouteActionType.GET_ALL_ROUTES);
export const getAllRoutesSuccess = createAction(
  RouteActionType.GET_ALL_ROUTES_SUCCESS,
  props<{ routes: Route[] }>()
);
