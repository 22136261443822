import { Map } from './map.model';

export interface MapState {
  maps: Map[];
  map: Map;
  loadingMaps: boolean;
  loadingMap: boolean;
  mapUpdated: boolean;
  mapDeleted: boolean;
}

export const emptyMap: Map = {
  name: '',
  level: '',
  constraints: [],
  programs: [],
};

export const initialMapState: MapState = {
  maps: [],
  map: emptyMap,
  loadingMaps: false,
  loadingMap: false,
  mapUpdated: false,
  mapDeleted: false,
};
