import { createReducer, on } from '@ngrx/store';
import { emptyRouteMap, initialRouteMapState } from './route-map.state';
import { createRouteMapSuccess, deleteRouteMapSuccess, getAllRouteMaps, getAllRouteMapsSuccess, getRouteMap, getRouteMapSuccess, resetRouteMapDeleted, resetRouteMapUpdated, updateRouteMapSuccess } from './route-map.actions';

export const routeMapReducer = createReducer(
  initialRouteMapState,
  on(getAllRouteMaps, state => ({ ...state, loadingRouteMaps: true })),
  on(getAllRouteMapsSuccess, (state, { routeMaps }) => ({
    ...state,
    routeMaps: routeMaps,
    loadingRouteMaps: false,
  })),
  on(getRouteMap, state => ({ ...state, loadingRouteMap: true })),
  on(getRouteMapSuccess, (state, { routeMap }) => ({ ...state, routeMap, loadingRouteMap: false })),
  on(createRouteMapSuccess, (state, { routeMap }) => ({
    ...state,
    routeMap,
    routeMapUpdated: true,
  })),
  on(updateRouteMapSuccess, (state, { routeMap }) => ({
    ...state,
    routeMap,
    routeMapUpdated: true,
  })),
  on(resetRouteMapUpdated, state => ({ ...state, routeMapUpdated: false })),
  on(deleteRouteMapSuccess, state => ({ ...state, routeMap: emptyRouteMap, routeMapDeleted: true })),
  on(resetRouteMapDeleted, state => ({ ...state, routeMapDeleted: false }))
);
