import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProgramApiService } from '../../api/program-api.service';
import { ProgramActionType, updateProgram, updateProgramSuccess } from './program.actions';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';

@Injectable()
export class ProgramEffects {
  getAllPrograms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramActionType.GET_ALL_PROGRAMS),
      exhaustMap(() =>
        this.programAPI.getAllPrograms().pipe(
          map(programs => ({
            type: ProgramActionType.GET_ALL_PROGRAMS_SUCCESS,
            programs: programs,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramActionType.GET_PROGRAM),
      exhaustMap((action: any) =>
        this.programAPI.getProgram(action.id).pipe(
          map(program => ({
            type: ProgramActionType.GET_PROGRAM_SUCCESS,
            program: program,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramActionType.CREATE_PROGRAM),
      exhaustMap((action: any) =>
        this.programAPI.createProgram(action.program).pipe(
          map(program => ({
            type: ProgramActionType.CREATE_PROGRAM_SUCCESS,
            program: program,
          })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProgram),
      exhaustMap(action =>
        this.programAPI.updateProgram(action.program).pipe(
          map(program => updateProgramSuccess({ program })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProgramActionType.DELETE_PROGRAM),
      exhaustMap((action: any) =>
        this.programAPI.deleteProgram(action.id).pipe(
          map(() => ({ type: ProgramActionType.DELETE_PROGRAM_SUCCESS })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private programAPI: ProgramApiService
  ) {}
}
