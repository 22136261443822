import { AdminMap } from './admin.model';

export type AdminState = {
  adminMaps: AdminMap[];
  loadingAdminMaps: boolean;
};

export const initialAdminState: AdminState = {
  adminMaps: [],
  loadingAdminMaps: false,
};
