import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, catchError, exhaustMap, map } from "rxjs";
import { OriginApiService } from "../../api/origin-api.service";
import { getAllOrigins, getAllOriginsSuccess } from "./origin.action";

@Injectable()
export class OriginEffects {
  getAllOrigins$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllOrigins),
      exhaustMap(() =>
        this.originAPI.getAllOrigins().pipe(
          map(origins => getAllOriginsSuccess({origins})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private originAPI: OriginApiService
  ) {}
}
