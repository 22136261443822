import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';
import {
  createElementValue,
  createElementValueSuccess,
  deleteElementValue,
  deleteElementValueSuccess,
  getAllElementValues,
  getAllElementValuesSuccess,
  getElementValue,
  getElementValueSuccess,
  updateElementValue,
  updateElementValueSuccess,
} from './element-value.action';
import { ElementValueApiService } from '../../api/element-value-api.service';

@Injectable()
export class ElementValuesEffects {
  constructor(
    private actions$: Actions,
    private elementValueAPI: ElementValueApiService
  ) {}

  getAllElementValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllElementValues),
      exhaustMap(() =>
        this.elementValueAPI.getAllElementValues().pipe(
          map(elementValues => getAllElementValuesSuccess({ elementValues })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getElementValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getElementValue),
      exhaustMap(({ id }) =>
        this.elementValueAPI.getElementValue(Number(id)).pipe(
          map(elementValue => getElementValueSuccess({ elementValue })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createElementValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createElementValue),
      exhaustMap(({ elementValue }) =>
        this.elementValueAPI.createElementValue(elementValue).pipe(
          map(elementValue => createElementValueSuccess({ elementValue })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateElementValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateElementValue),
      exhaustMap(({ elementValue }) =>
        this.elementValueAPI.updateElementValue(elementValue).pipe(
          map(elementValue => updateElementValueSuccess({ elementValue })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteElementValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteElementValue),
      exhaustMap(({ id }) =>
        this.elementValueAPI.deleteElementValue(id).pipe(
          map(() => deleteElementValueSuccess()),
          catchError(() => EMPTY)
        )
      )
    )
  );
}
