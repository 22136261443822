import { ElementValuesState } from './element-value.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const elementValueFeatureKey = 'elementValue';
export const selectElementFeature = createFeatureSelector<ElementValuesState>(elementValueFeatureKey);

export const selectAllElementValues = createSelector(
  selectElementFeature,
  (elementValueState: ElementValuesState) => elementValueState.elementValues
);
export const selectLoadingElementValues = createSelector(
  selectElementFeature,
  (elementValueState: ElementValuesState) => elementValueState.loadingElementValues
);
export const selectElementValue = createSelector(
  selectElementFeature,
  (elementValueState: ElementValuesState) => elementValueState.elementValue
);
export const selectLoadingElementValue = createSelector(
  selectElementFeature,
  (elementValueState: ElementValuesState) => elementValueState.loadingElementValue
);
export const selectElementValuesUpdated = createSelector(
  selectElementFeature,
  (elementValueState: ElementValuesState) => elementValueState.elementValueUpdated
);
export const selectElementValuesDeleted = createSelector(
  selectElementFeature,
  (elementValueState: ElementValuesState) => elementValueState.elementValueDeleted
);
