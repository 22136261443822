import { createReducer, on } from "@ngrx/store";
import { emptyInductionLocation, initialInductionLocationState } from "./induction-location.state";
import { createInductionLocationSuccess, deleteInductionLocationSuccess, getAllInductionLocations, getAllInductionLocationsSuccess, getInductionLocation, getInductionLocationSuccess, resetInductionLocationDeleted, resetInductionLocationUpdated, updateInductionLocationSuccess } from "./induction-location.actions";

export const inductionLocationReducer = createReducer(
    initialInductionLocationState,
    on(getAllInductionLocations, state => ({ ...state, loadingInductionLocations: true })),
    on(getAllInductionLocationsSuccess, (state, { inductionLocations }) => ({
      ...state,
      inductionLocations: inductionLocations,
      loadingInductionLocations: false,
    })),
    on(getInductionLocation, state => ({ ...state, loadingInductionLocation: true })),
    on(getInductionLocationSuccess, (state, { inductionLocation }) => ({ ...state, inductionLocation, loadingInductionLocation: false })),
    on(createInductionLocationSuccess, (state, { inductionLocation }) => ({
      ...state,
      inductionLocation,
      inductionLocationUpdated: true,
    })),
    on(updateInductionLocationSuccess, (state, { inductionLocation }) => ({
      ...state,
      inductionLocation,
      inductionLocationUpdated: true,
    })),
    on(resetInductionLocationUpdated, state => ({ ...state, inductionLocationUpdated: false })),
    on(deleteInductionLocationSuccess, state => ({ ...state, inductionLocation: emptyInductionLocation, inductionLocationDeleted: true })),
    on(resetInductionLocationDeleted, state => ({ ...state, inductionLocationDeleted: false }))
  );
