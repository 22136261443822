import { createAction, props } from "@ngrx/store";
import { Contact } from "./contact.model";

export enum ContactActionType {
  GET_ALL_CONTACTS = '[Contact] Get All Contacts',
  GET_ALL_CONTACTS_SUCCESS = '[Contact] Get All Contacts :: Success'
}

export const getAllContacts = createAction(ContactActionType.GET_ALL_CONTACTS);
export const getAllContactsSuccess = createAction(
  ContactActionType.GET_ALL_CONTACTS_SUCCESS,
  props<{ contacts: Contact[] }>()
);
