import { createReducer, on } from "@ngrx/store";
import { initialProgramOriginState } from "./program-origin.state";
import { getAllProgramOriginsSuccess } from "./program-origin.action";

export const programOriginReducer = createReducer(
  initialProgramOriginState,
  on(getAllProgramOriginsSuccess, (state, { programOrigins }) => ({
    ...state,
    programOrigins,
  }))
);
