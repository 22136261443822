@if (getSpinnerStatus() | async) {
  <div class="onboardr-spinner__overlay"></div>
  <div class="onboardr-spinner">
    <div class="onboardr-spinner__container">
      <img
        class="onboardr-spinner__logo"
        alt="OnBoardr Logo"
        src="assets/images/onboardr-logo.svg" />
    </div>
  </div>
}
