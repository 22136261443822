import { Constraint } from './constraints.model';

export interface ConstraintState {
  constraints: Constraint[];
}

export const emptyConstraint: Constraint = {
  elementId: null,
  mapId: null,
  schemeId: null,
  constraints: null
};

export const initialConstraintState: ConstraintState = {
  constraints: [],
};
