import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ElementValue } from '../store/element-value/element-value.model';

@Injectable({
  providedIn: 'root',
})
export class ElementValueApiService {
  private http = inject(HttpClient);

  getAllElementValues(): Observable<ElementValue[]> {
    return this.http.get<ElementValue[]>(environment.apiRoutes.elementValues);
  }

  getElementValue(id: number): Observable<ElementValue> {
    return this.http.get<ElementValue>(`${environment.apiRoutes.elementValues}${id}`);
  }

  createElementValue(elementValue: ElementValue): Observable<ElementValue> {
    return this.http.post<ElementValue>(environment.apiRoutes.elementValues, elementValue);
  }

  updateElementValue(elementValue: ElementValue): Observable<ElementValue> {
    return this.http.post<ElementValue>(
      `${environment.apiRoutes.elementValues}${elementValue.id}`,
      elementValue
    );
  }

  deleteElementValue(id: number): Observable<ElementValue> {
    return this.http.delete<ElementValue>(`${environment.apiRoutes.elementValues}${id}`);
  }
}
