import { ROLE_ADMIN, ROLE_USER, UserRole } from '../../../../core/utils/roles';

type DashboardRoute = {
  icon?: string;
  svgIcon?: string;
  fontSet?: string;
  route: string;
  label: string;
  rolesAllowed: UserRole[];
};

export const DASHBOARD_ROUTES: DashboardRoute[] = [
  {
    icon: null,
    svgIcon: 'onboardr-admin',
    route: '/dashboard/admin',
    label: 'Admin',
    rolesAllowed: [ROLE_ADMIN],
  },
  {
    svgIcon: 'onboardr-companies',
    route: '/dashboard/companies',
    label: 'Companies',
    rolesAllowed: [ROLE_ADMIN, ROLE_USER],
  },
];
