import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { InductionLocationApiService } from "../../api/induction-location-api.service";
import { EMPTY, catchError, exhaustMap, map } from "rxjs";
import { createInductionLocation, createInductionLocationSuccess, deleteInductionLocation, deleteInductionLocationSuccess, getAllInductionLocations, getAllInductionLocationsSuccess, getInductionLocation, getInductionLocationSuccess, updateInductionLocation, updateInductionLocationSuccess } from "./induction-location.actions";

@Injectable()
export class InductionLocationEffects {
    getAllInductionLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllInductionLocations),
      exhaustMap(() =>
        this.inductionLocationAPI.getAllInductionLocations().pipe(
          map(inductionLocations => getAllInductionLocationsSuccess({inductionLocations})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getInductionLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInductionLocation),
      exhaustMap((action) =>
        this.inductionLocationAPI.getInductionLocation(Number(action.id)).pipe(
          map(inductionLocation => getInductionLocationSuccess({inductionLocation})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createInductionLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInductionLocation),
      exhaustMap(action =>
        this.inductionLocationAPI.createInductionLocation(action.inductionLocation).pipe(
          map(inductionLocation => createInductionLocationSuccess({inductionLocation})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateInductionLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateInductionLocation),
      exhaustMap((action) =>
        this.inductionLocationAPI.updateInductionLocation(action.inductionLocation).pipe(
          map(inductionLocation => updateInductionLocationSuccess({inductionLocation})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  deleteInductionLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteInductionLocation),
      exhaustMap((action) =>
        this.inductionLocationAPI.deleteInductionLocation(action.id).pipe(
          map(() => deleteInductionLocationSuccess()),
          catchError(() => EMPTY)
        )
      )
    )
  );

    constructor(
        private actions$: Actions,
        private inductionLocationAPI: InductionLocationApiService
    ) {}
}
