import { createReducer, on } from '@ngrx/store';
import { emptyElement, initialElementState } from './element.state';
import {
  createElementSuccess,
  deleteElementSuccess,
  getAllElements,
  getAllElementsSuccess,
  getElement,
  getElementSuccess,
  resetElementDeleted,
  resetElementUpdated,
  updateElementsSuccess,
} from './element.action';

export const elementReducer = createReducer(
  initialElementState,
  on(getAllElements, state => ({ ...state, loadingElements: true })),
  on(getAllElementsSuccess, (state, { elements }) => ({
    ...state,
    elements,
    loadingElements: false,
  })),
  on(getElement, state => ({ ...state, loadingElement: true })),
  on(getElementSuccess, (state, { element }) => ({
    ...state,
    element,
    loadingElement: false,
  })),
  on(createElementSuccess, (state, { element }) => ({
    ...state,
    element,
    elementUpdated: true,
  })),
  on(updateElementsSuccess, (state, { elements }) => ({
    ...state,
    elements,
    elementUpdated: true,
  })),
  on(resetElementUpdated, state => ({ ...state, elementUpdated: false })),
  on(deleteElementSuccess, state => ({
    ...state,
    element: emptyElement,
    elementDeleted: true,
  })),
  on(resetElementDeleted, state => ({ ...state, elementDeleted: false }))
);
