import { Scheme } from './scheme.model';

export interface SchemeState {
  schemes: Scheme[];
  scheme: Scheme;
  loadingSchemes: boolean;
  loadingScheme: boolean;
  schemeUpdated: boolean;
  schemeDeleted: boolean;
}

export const emptyScheme: Scheme = {
  name: '',
  constraints: [],
  schemeType: null,
  schemeTypeId: null,
};

export const initialSchemeState: SchemeState = {
  schemes: [],
  scheme: emptyScheme,
  loadingSchemes: false,
  loadingScheme: false,
  schemeUpdated: false,
  schemeDeleted: false,
};
