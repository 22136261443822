import { createReducer, on } from '@ngrx/store';
import { emptyCompany, initialCompanyState } from './company.state';
import {
  clearCompany,
  createCompanySuccess,
  deleteCompanySuccess,
  getAllCompaniesSuccess,
  getCompany,
  getCompanySuccess,
  getReport,
  getReportSuccess,
  resetCompanyDeleted,
  resetCompanyUpdated,
  resetUserInfo,
  setUserInfo,
  updateCompanySuccess,
} from './company.action';

export const companyReducer = createReducer(
  initialCompanyState,
  on(getAllCompaniesSuccess, (state, { companies }) => ({
    ...state,
    companies: companies,
  })),
  on(getCompany, state => ({ ...state, loadingCompany: true })),
  on(getCompanySuccess, (state, { company }) => ({
    ...state,
    company: company,
    loadingCompany: false,
  })),
  on(getReport, state => ({ ...state, loadingCompany: true })),
  on(getReportSuccess, (state, { company }) => ({
    ...state,
    company: company,
    loadingCompany: false,
  })),
  on(clearCompany, state => ({ ...state, company: emptyCompany })),
  on(createCompanySuccess, (state, { company }) => ({
    ...state,
    company: company,
    companyUpdated: true,
  })),
  on(updateCompanySuccess, (state, { company }) => ({
    ...state,
    company: company,
    companyUpdated: true,
  })),
  on(resetCompanyUpdated, state => ({ ...state, companyUpdated: false })),
  on(deleteCompanySuccess, state => ({
    ...state,
    company: emptyCompany,
    companyDeleted: true,
  })),
  on(resetCompanyDeleted, state => ({ ...state, companyDeleted: false })),
  on(setUserInfo, (state, {userInfo}) => ({ ...state, createByName: userInfo.createByName, createByEmail: userInfo.createByEmail})),
  on(resetUserInfo, state => ({ ...state, createByName: '', createByEmail: '' }))
);
