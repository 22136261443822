import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Element } from '../store/element/element.model';

@Injectable({
  providedIn: 'root',
})
export class ElementsApiService {
  private http = inject(HttpClient);

  getAllElements(): Observable<Element[]> {
    return this.http.get<Element[]>(environment.apiRoutes.elements);
  }

  getElement(id: number): Observable<Element> {
    return this.http.get<Element>(`${environment.apiRoutes.elements}${id}`);
  }

  createElement(element: Element): Observable<Element> {
    return this.http.post<Element>(environment.apiRoutes.elements, element);
  }

  updateElement(element: Element): Observable<Element> {
    return this.http.patch<Element>(`${environment.apiRoutes.elements}`, element);
  }

  updateElements(elements: Element[]): Observable<Element[]> {
    return this.http.patch<Element[]>(
      `${environment.apiRoutes.elements}updateElements`,
      elements
    );
  }

  deleteElement(id: number): Observable<Element> {
    return this.http.delete<Element>(`${environment.apiRoutes.elements}${id}`);
  }
}
