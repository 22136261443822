import { CanActivateFn, Router } from '@angular/router';
import { getLocalStorage } from '../../login/utils/local-storage-utils';
import { AUTH_VARIABLES } from '../../login/utils/auth-variables';
import { AuthenticationService } from '../../login/services/authentication.service';
import { inject } from '@angular/core';

export const roleGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  const rolesAllowed: string[] = route.data?.['roles'];
  if (!rolesAllowed || rolesAllowed?.length === 0) return true;

  const userRole = getLocalStorage(AUTH_VARIABLES.userRole);
  if (!userRole) {
    authService.signOut();
    router.navigate(['/login']);
    return false;
  }

  if (!rolesAllowed.includes(userRole)) {
    router.navigate(['/login']);
  }

  return true;
};
