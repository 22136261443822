import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ConstraintApiService } from "../../api/constraint-api.service";
import { EMPTY, catchError, exhaustMap, map } from "rxjs";
import { getAllConstraints, getAllConstraintsSuccess } from "./constraint.action";

@Injectable()
export class ConstraintEffects {
  getAllConstraints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllConstraints),
      exhaustMap(() =>
        this.constraintAPI.getAllConstraints().pipe(
          map(constraints => getAllConstraintsSuccess({constraints})),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private constraintAPI: ConstraintApiService
  ) {}
}
