import { Component, OnInit, inject, signal } from '@angular/core';
import { NgClass } from '@angular/common';
import { Store } from '@ngrx/store';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

import { DASHBOARD_ROUTES } from './utils/dashboard-routes';
import { AuthenticationService } from '../../../login/services/authentication.service';
import { OnboardrButtonComponent } from '../../../ui/onboardr-button/onboardr-button.component';
import { resetUserInfo } from '../../../core/store/company/company.action';
import { AUTH_VARIABLES } from '../../../login/utils/auth-variables';
import { removeLocalStorage } from './../../../login/utils/local-storage-utils';

@Component({
  selector: 'app-dashboard-layout',
  standalone: true,
  imports: [
    NgClass,
    RouterOutlet,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    OnboardrButtonComponent,
  ],
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss',
})
export class DashboardLayoutComponent implements OnInit {
  private readonly store = inject(Store);
  private authService = inject(AuthenticationService);
  dashboardRoutes = signal([]);

  constructor(
    private auth: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getUserDashboardRoutes();
  }

  getUserDashboardRoutes(): void {
    const userRole = this.auth.getUserRole();
    const userRoutes = DASHBOARD_ROUTES.filter(route => route.rolesAllowed.includes(userRole));
    this.dashboardRoutes.set(userRoutes);
  }

  signOut() {
    this.auth.signOut().subscribe(() => {
      removeLocalStorage(AUTH_VARIABLES.companyUserInfo);
      this.store.dispatch(resetUserInfo());
      this.router.navigate(['']);
    });
  }
}
