import { Component, inject } from '@angular/core';
import { SpinnerService } from '../../services/spinner.service';
import { AsyncPipe } from '@angular/common';
import { tap } from 'rxjs';

@Component({
  selector: 'app-onboardr-spinner',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './onboardr-spinner.component.html',
  styleUrl: './onboardr-spinner.component.scss',
})
export class OnboardrSpinnerComponent {
  public spinnerService = inject(SpinnerService);

  getSpinnerStatus() {
    return this.spinnerService.getSpinnerListener().pipe(
      tap(spinnerStatus => {
        if (spinnerStatus) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      })
    );
  }
}
