import { ElementValue } from './element-value.model';

export interface ElementValuesState {
  elementValues: ElementValue[];
  elementValue: ElementValue;
  loadingElementValues: boolean;
  loadingElementValue: boolean;
  elementValueUpdated: boolean;
  elementValueDeleted: boolean;
}

export const emptyElementValue: ElementValue = {
  id: null,
  element: null,
  elementId: null,
  routeId: null,
  route: null,
  value: {},
};

export const initialElementState: ElementValuesState = {
  elementValues: [],
  elementValue: emptyElementValue,
  loadingElementValues: false,
  loadingElementValue: false,
  elementValueUpdated: false,
  elementValueDeleted: false,
};
