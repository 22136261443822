import { createAction, props } from "@ngrx/store";
import { Address } from "./address.model";

export enum AddressActionType {
  GET_ALL_ADDRESSES = '[Address] Get All Addresses',
  GET_ALL_ADDRESSES_SUCCESS = '[Address] Get All Addresses :: Success'
}

export const getAllAddresses = createAction(AddressActionType.GET_ALL_ADDRESSES);
export const getAllAddressesSuccess = createAction(
  AddressActionType.GET_ALL_ADDRESSES_SUCCESS,
  props<{ addresses: Address[] }>()
);
