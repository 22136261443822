import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  GetAllAdminMapsAction,
  GetAllAdminMapsSuccessAction,
  UpdateAdminCurrentMapAction,
  UpdateAdminCurrentSchemeAction,
} from './admin.actions';
import { EMPTY, catchError, exhaustMap, forkJoin, map } from 'rxjs';
import { MapApiService } from '../../api/map-api.service';
import { AdminMap } from './admin.model';
import { Map } from '../map/map.model';
import { ADMIN_MAP_CONSTS } from '../../../admin/components/admin-maps/utils/admin-map-consts';
import { SchemeApiService } from '../../api/scheme-api.service';
import { Scheme } from '../scheme/scheme.model';
import { ProgramApiService } from '../../api/program-api.service';
import { Program } from '../program/program.model';

@Injectable()
export class AdminEffects {
  private programsAPI = inject(ProgramApiService);

  constructor(
    private action$: Actions,
    private mapAPI: MapApiService,
    private schemeAPI: SchemeApiService
  ) {}

  getAllAdminMaps$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetAllAdminMapsAction),
      exhaustMap(() => {
        const maps$ = this.mapAPI.getAllMaps();
        const schemes$ = this.schemeAPI.getAllSchemes();
        const programs$ = this.programsAPI.getAllPrograms();

        return forkJoin([maps$, schemes$, programs$]).pipe(
          map(([maps, schemes, programs]) => {
            const adminMaps: AdminMap[] = getAdminMapsFromAllMaps({ maps, schemes, programs });
            return GetAllAdminMapsSuccessAction({ adminMaps: adminMaps });
          }),
          catchError(() => EMPTY)
        );
      })
    )
  );

  updateAdminCurrentScheme$ = createEffect(() =>
    this.action$.pipe(
      ofType(UpdateAdminCurrentSchemeAction),
      exhaustMap(payload => {
        const { scheme } = payload;
        return this.schemeAPI.updateScheme(scheme).pipe(
          map(() => {
            return GetAllAdminMapsAction();
          }),
          catchError(error => {
            return EMPTY;
          })
        );
      })
    )
  );

  updateAdminCurrentMap$ = createEffect(() =>
    this.action$.pipe(
      ofType(UpdateAdminCurrentMapAction),
      exhaustMap(payload => {
        return this.mapAPI.updateMap(payload.map).pipe(
          map(() => GetAllAdminMapsAction()),
          catchError(error => EMPTY)
        );
      })
    )
  );
}

type GetAdminMapsFromAllMapsProps = {
  maps: Map[];
  schemes: Scheme[];
  programs: Program[];
};

const getAdminMapsFromAllMaps = (props: GetAdminMapsFromAllMapsProps): AdminMap[] => {
  const { maps, schemes, programs } = props;

  const sortByIdNumber = (a: { id?: number }, b: { id?: number }) => {
    if (a?.id > b?.id) return 1;
    if (a?.id < b?.id) return -1;
    return 0;
  };

  const companyMaps = maps.filter(map => map.level === 'company');

  const companyMapsDict = companyMaps.reduce((prev, curr) => {
    return { ...prev, [curr.id]: curr };
  }, {});

  const adminMaps: AdminMap[] = [
    {
      id: ADMIN_MAP_CONSTS.PROGRAMS,
      name: 'Programs',
      options: maps,
      programOptions: [...programs]
        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
        .sort((a, b) => a.id - b.id),
      companyMapDict: companyMapsDict,
    },
    {
      id: ADMIN_MAP_CONSTS.COMPANY_MAP_EDITOR,
      name: 'Company Maps',
      options: companyMaps.sort(sortByIdNumber),
    },
    {
      id: ADMIN_MAP_CONSTS.ORIGIN_MAP_EDITOR,
      name: 'Origin Maps',
      options: maps.filter(map => map.level === 'origin'),
    },
    {
      id: ADMIN_MAP_CONSTS.ROUTE_MAP_EDITOR,
      name: 'Process Flow Maps',
      options: maps,
    },
    // Scheme Types are now handled in Elements
    // {
    //   id: ADMIN_MAP_CONSTS.SCHEME_TYPES,
    //   name: 'Scheme Type',
    //   options: [],
    // },
    {
      id: ADMIN_MAP_CONSTS.ELEMENTS,
      name: 'Scheme Types',
      options: [],
    },
    {
      id: ADMIN_MAP_CONSTS.SCHEMES,
      name: 'Schemes',
      options: schemes.sort(sortByIdNumber),
    },
    {
      id: ADMIN_MAP_CONSTS.INDUCTION_LOCATIONS,
      name: 'Induction Locations',
      options: [],
    },
    {
      id: ADMIN_MAP_CONSTS.USERS,
      name: 'Users',
      options: [],
    },
    {
      id: ADMIN_MAP_CONSTS.EXTRAS,
      name: 'Extras',
      options: [],
    },
  ];
  adminMaps.sort((a, b) => a.id - b.id);

  return adminMaps;
};
