import { createReducer, on } from "@ngrx/store";
import { initialConstraintState } from "./constraint.state";
import { getAllConstraintsSuccess } from "./constraint.action";

export const constraintReducer = createReducer(
  initialConstraintState,
  on(getAllConstraintsSuccess, (state, { constraints }) => ({
    ...state,
    constraints,
  }))
);
