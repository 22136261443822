import { createReducer, on } from '@ngrx/store';
import { emptyElementValue, initialElementState } from './element-value.state';
import {
  createElementValue,
  deleteElementValueSuccess,
  getAllElementValues,
  getAllElementValuesSuccess,
  getElementValue,
  getElementValueSuccess,
  resetElementValuesDeleted,
  resetElementValuesUpdated,
  updateElementValueSuccess,
} from './element-value.action';

export const elementValueReducer = createReducer(
  initialElementState,
  on(getAllElementValues, state => ({ ...state, loadingElementValues: true })),
  on(getAllElementValuesSuccess, (state, { elementValues }) => ({
    ...state,
    elementValues,
    loadingElementValues: false,
  })),
  on(getElementValue, state => ({ ...state, loadingElementValue: true })),
  on(getElementValueSuccess, (state, { elementValue }) => ({
    ...state,
    elementValue,
    loadingElementValue: false,
  })),
  on(createElementValue, (state, { elementValue }) => ({
    ...state,
    elementValue,
    elementValueUpdated: true,
  })),
  on(updateElementValueSuccess, (state, { elementValue }) => ({
    ...state,
    elementValue,
    elementValueUpdated: true,
  })),
  on(deleteElementValueSuccess, state => ({
    ...state,
    elementValue: emptyElementValue,
    elementValueDeleted: true,
  })),
  on(resetElementValuesUpdated, state => ({ ...state, elementValueUpdated: false })),
  on(resetElementValuesDeleted, state => ({ ...state, elementValueDeleted: false }))
);
