import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../../login/services/authentication.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  const isUserLogged = authService.isUserLoggedIn();
  const didUserSayWhoHeIs = authService.didUserSayWhoHeIs();

  if (!isUserLogged || !didUserSayWhoHeIs) {
    authService.signOut();
    router.navigate(['/login']);
    return false;
  }

  return true;
};
